import { Component, OnInit } from '@angular/core';
import './../../../assets/js/smtp.js';
import { Contact } from './../../models/contact';
import { NgForm } from '@angular/forms';

declare let Email: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactModel = new Contact('','','','','','','','');
  constructor() { }

  ngOnInit() {
  }

  onSubmit(form: Contact) {
    console.log('Your form data : ', this.contactModel.firstname);
    Email.send({
      Host : 'smtp.elasticemail.com',
      Username : 'thibault.neel@gmail.com',
      Password : 'BD27FC06BA320C29EEDC49043C1BD1E47965',
      To : 'lacabaneamijo@gmail.com',
      From : 'thibault.neel@gmail.com',
      Subject : 'La cabane a Mijo : Prise de contact',
      Body : '<i>Ceci est un message de prise de contact </i> <br/><br/> <b>Prénom : </b>' + this.contactModel.firstname + '<br /> <b>Nom : </b>' + this.contactModel.name + '<br /> <b>Adresse email : </b>' + this.contactModel.email + '<br /> <b>Numéro de téléphone : </b>' + this.contactModel.phone + '<br /> <b>Date évènement : </b>' + this.contactModel.date + '<br /> <b>Lieu évènement : </b>' + this.contactModel.location + '<br /> <b>Nombre de convives : </b>' + this.contactModel.volum + '<br /> <b>Message :</b> <br />' + this.contactModel.message + '<br><br> <b>Ceci est un message automatique, merci de ne pas y répondre</b>'
      }).then( message => {alert('Votre prise de contact a été envoyée, nous prendrons contact avec vous dans les plus brefs délais. Merci.'); this.resetForm() } );
  }

  resetForm()
  {
    this.contactModel.firstname = '';
    this.contactModel.name = '';
    this.contactModel.phone = '';
    this.contactModel.email = '';
    this.contactModel.message = '';
    this.contactModel.date = '';
    this.contactModel.location = '';
    this.contactModel.volum = '';
  }
}
