import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-producteurs',
  templateUrl: './producteurs.component.html',
  styleUrls: ['./producteurs.component.css']
})
export class ProducteursComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
