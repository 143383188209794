import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { SliderComponent } from './components/slider/slider.component';
import { ConceptComponent } from './components/concept/concept.component';
import { GuinguetteComponent } from './components/guinguette/guinguette.component';
import { EventComponent } from './components/event/event.component';
import { ProducteursComponent } from './components/producteurs/producteurs.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    PortfolioComponent,
    ContactComponent,
    FooterComponent,
    TitleBarComponent,
    SliderComponent,
    ConceptComponent,
    GuinguetteComponent,
    EventComponent,
    ProducteursComponent
  ],
  imports: [
    BrowserModule,
    FormsModule 
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
