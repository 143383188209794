export class Contact {
    constructor(
        public firstname: string,
        public name: string,
        public email: string,
        public phone: string,
        public message: string,
        public date: string,
        public location: string,
        public volum: string,
    ) {}
}
